import AlbaniaInAcion from './assets/images/shqiponja-aps.gif'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={AlbaniaInAcion} className="" alt="albania-in-action" />
        <p>
          ...Së shpejti!
        </p>

      </header>
    </div>
  );
}

export default App;
